import React, { useEffect } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { ValidateMessage } from "utils/constant";
import InputController from "components/Form/InputController";

export default function KolInteractDialog({
  isEdit,
  data,
  isOpen,
  onOpen,
  onClose,
  kolData,
  fetchData,
}) {
  const cancelRef = React.useRef();
  const toast = useToast();

  const [{ loading: assignLoading }, setInteractApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.SET_INTERACT,
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    like: yup
      .number()
      .nullable()
      .typeError(ValidateMessage.mustNumber)
      .min(0, ValidateMessage.minNumber)
      .required(ValidateMessage.required),
    dislike: yup
      .number()
      .nullable()
      .typeError(ValidateMessage.mustNumber)
      .min(0, ValidateMessage.minNumber)
      .required(ValidateMessage.required),
    heart: yup
      .number()
      .nullable()
      .typeError(ValidateMessage.mustNumber)
      .min(0, ValidateMessage.minNumber)
      .required(ValidateMessage.required),
  });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      like: 0,
      dislike: 0,
      heart: 0,
    },
  });

  useEffect(() => {
    reset({
      like: data?.numberLike,
      dislike: data?.numberDislike,
      heart: data?.numberHeart,
    });
  }, [data]);

  const onSubmit = (values) => {
    setInteractApi({
      data: {
        ...values,
        username: data?.name,
      },
    })
      .then(() => {
        toast({
          title: "Update Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose?.();
        fetchData?.();
      })
      .catch(() => {
        toast({
          title: "Update Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <AlertDialog
        size="3xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{`Update like, dislike, heart commentator: ${data?.name}`}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController
                type="number"
                control={control}
                name="like"
                label="Like"
                isRequired
              />
              <InputController
                type="number"
                control={control}
                name="dislike"
                label="Dislike"
                isRequired
                styleContainer={{ pt: 4 }}
              />
              <InputController
                type="number"
                control={control}
                name="heart"
                label="Heart"
                isRequired
                styleContainer={{ pt: 4 }}
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={assignLoading}
              disabled={!isDirty}
              onClick={handleSubmit(onSubmit)}
            >
              Update
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
