import moment from "moment-timezone";

export const isJsonString = (str) => {
  if (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export function rangeDesc(start, end) {
  return Array(start - end + 1)
    .fill()
    .map((_, idx) => start - idx);
}

export const today = moment().tz("Asia/Ho_Chi_Minh").format("YYYY-MM-DD");

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const checkDataChanged = (data) =>
  Object.values(data).some((item) => item?.file);

export const downloadFile = (file, name) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
