import React, { useState, useEffect, useMemo } from "react";

import {
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Avatar,
  useToast,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";

import { axiosPost } from "../../utils/api";
import { get } from "lodash";
import {
  ContentTypeOption,
  LINK_REGEX,
  ContentType,
  API_ROUTES,
  ROOT_API,
} from "utils/constant";
import { FaFilm, FaVideo, FaPhotoVideo } from "react-icons/fa";
import { MAX_VIDEO_UPLOAD } from "utils/constant";
import Loading from "components/Layout/Loading";

const createContentApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_CONTENT;

const updateContentApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_CONTENT_UPDATE;

const ContentRegisterDialog = ({
  isOpen,
  onOpen,
  onClose,
  fetchData,
  data,
}) => {
  const cancelRef = React.useRef();
  const fileInput = React.useRef(null);

  const [name, setName] = useState("");
  const [type, setType] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const toast = useToast();
  const [fileName, setFileName] = useState("");
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      // const imageName = data?.thumbnailUrl
      //   ? data.thumbnailUrl.split("/").pop()
      //   : "";
      // setFileName(imageName);
      setName(data.name);
      setType(ContentTypeOption.find((item) => item.value === data?.type));
      setVideoUrl(data.videoUrl);
    }
  }, [data]);

  const clickUpdateButton = async () => {
    if (!name) {
      toast({
        title: "Name Is Require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (!videoUrl) {
      toast({
        title: "Link Video Is Require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (!type) {
      toast({
        title: "Type Is Require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    let contentData = {
      matchId: data?.matchId,
      videoUrl,
      name,
      type: type.value,
    };
    if (data) {
      contentData = {
        ...contentData,
        content_id: data?._id,
      };
    }
    try {
      const response = await axiosPost(
        data ? updateContentApi : createContentApi,
        contentData
      );
      if (response?.data?.code == 0) {
        toast({
          title: data
            ? "Update Content Successfully"
            : "Create Content Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        fetchData();
        onClose();
        setName("");
        setVideoUrl("");
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          (data ? "Update Content Fail" : "Create Content Fail"),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const onChangeVideo = () => {
    const btn = fileInput?.current;
    if (btn !== null) {
      btn.click();
    }
  };

  const onCloseDialog = () => {
    if (!data) {
      setName("");
      setVideoUrl("");
    }

    onClose();
  };
  const handleChangeFile = async (event) => {
    if (!name) {
      toast({
        title: "Name Is Require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (!type) {
      toast({
        title: "Type Is Require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (event.target.files.length === 0) return;
    const fileImage = event.target.files[0];
    const regex = /\.(mp4)$/i;
    if (!regex.test(fileImage?.name)) {
      toast({
        title: "Only video file mp4 uploading is supported",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const fsMb = fileImage.size / (1024 * 1024);
    const MAX_FILE_SIZE = 1000;
    if (fsMb > MAX_FILE_SIZE) {
      toast({
        title: "Max file size 1GB",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    setImage(fileImage);
    setFileName(fileImage?.name);

    const contentData = new FormData();
    contentData.append("videoFile", fileImage);
    contentData.append("name", name);
    const headers = {
      "content-type": "multipart/form-data",
    };
    setLoading(true);
    try {
      const response = await axiosPost(
        `${ROOT_API}${API_ROUTES.UPLOAD_VIDEO_MEDIA}?category=${type.value}`,
        contentData,
        headers
      );
      if (response?.data?.code == 0) {
        toast({
          title: "Upload Video Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setImage(null);
        const url = `${ROOT_API}${response?.data?.data?.path}`;
        setVideoUrl(url);
      }
    } catch (error) {
      console.log(error);
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Upload Video Fail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {!data ? "Create new content" : "Update the content"}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Type</FormLabel>
              <Select
                value={type}
                onChange={(selected) => setType(selected)}
                options={ContentTypeOption}
                placeholder={"Choose"}
                chakraStyles={{
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 10,
                  }),
                }}
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel display={"inline"}>Video File</FormLabel>
              {/* <Avatar
                cursor={"pointer"}
                w="200px"
                h="30px"
                borderRadius="4px"
                me="18px"
                icon={<FaPhotoVideo fontSize="1.5rem" />}
                mb={3}
                onClick={onChangeVideo}
              /> */}
              {!loading ? (
                <IconButton disabled={loading} onClick={onChangeVideo}>
                  <FaPhotoVideo fontSize="1.5rem" />
                </IconButton>
              ) : (
                <IconButton>
                  <Loading />
                </IconButton>
              )}
              {fileName && <Text>{fileName}</Text>}
              <Input
                hidden
                type="file"
                ref={fileInput}
                accept="video/*"
                onChange={(event) => handleChangeFile(event)}
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Video Url</FormLabel>
              <Input
                type="text"
                placeholder="Video Url"
                value={videoUrl}
                onChange={(event) => setVideoUrl(event.target.value)}
              />
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseDialog}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => clickUpdateButton()}
            >
              Update
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ContentRegisterDialog;
