import React, { useEffect, useState } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { ValidateMessage } from "utils/constant";
import InputController from "components/Form/InputController";
import SelectController from "components/Form/SelectController";

const UpdateLiveMatch = ({
  isEdit,
  data,
  isOpen,
  onOpen,
  onClose,
  fetchData,
}) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [matches, setMatches] = useState([]);

  const [{ loading: assignLoading }, assignLinkApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.CREATE_TIP,
    },
    { manual: true }
  );
  const [{ data: matchList }] = useAxios(
    {
      url: `${ROOT_API}${API_ROUTES.MATCH_CDN_HOT}`,
    },
    {
      useCache: false,
    }
  );

  const schema = yup.object().shape({
    matchId: yup.mixed().nullable().required(ValidateMessage.required),
    videoDiscuss: yup.string().nullable().required(ValidateMessage.required),
  });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      matchId: "",
      videoDiscuss: "",
    },
  });

    useEffect(() => {
      reset({
        matchId: null,
        videoDiscuss: data?.videoDiscuss,
      });
    }, [data]);

  const onSubmit = (values) => {
    assignLinkApi({
      data: {
        matchId: values?.matchId?.value,
        videoDiscuss: values?.videoDiscuss,
        commentatorId: data?.id,
      },
    })
      .then((response) => {
        toast({
          title: `${
            !data?.isTipCommentator ? "Phát" : "Đóng"
          } trực tiếp nhận định thành công`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose?.();
        fetchData();
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            `${
              !data?.isTipCommentator ? "Phát" : "Đóng"
            } trực tiếp nhận định thất bại`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    setMatches(
      matchList?.data?.map((item) => ({
        value: item?.matchId,
        label: `${item?.home_name} vs ${item?.away_name} - ${item.matchId}`,
      }))
    );
  }, [matchList?.data]);
  return (
    <>
      <AlertDialog
        size="3xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Update Live Stream Match</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <SelectController
                control={control}
                name="matchId"
                label="Match"
                isRequired
                options={matches}
              />
              <InputController
                control={control}
                name="videoDiscuss"
                label="Video Discuss"
                styleContainer={{ pt: "4" }}
                isRequired
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={assignLoading}
              disabled={!isDirty}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpdateLiveMatch;
