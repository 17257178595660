import React, { useEffect, useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  GridItem,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REQUEST_CRONJOB_STATUS, ValidateMessage } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { ROOT_API } from "utils/constant";
import useAxios from "axios-hooks";
import SelectController from "components/Form/SelectController";

const UpdateStatusMatch = ({ matchDetail, isOpen, onClose, fetchData }) => {
  const cancelRef = useRef();
  const toast = useToast();
  const [{ loading }, updateStatusApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.UPDATE_STATUS_MATCH,
    },
    { manual: true }
  );

  useEffect(() => {
    reset({
      status: Object.keys(REQUEST_CRONJOB_STATUS).map((key) => (
        {
          label: REQUEST_CRONJOB_STATUS[key],
          value: key,
        }
      ))?.find(item => matchDetail?.shortStatus === item.value)})
  }, [matchDetail])

  const schema = yup.object().shape({
    status: yup.mixed().nullable().required(ValidateMessage.required),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      status: undefined,
    },
  });

  const onSubmit = (dataForm) => {
    updateStatusApi({
      data: {
        matchId: matchDetail?.matchId,
        status: dataForm?.status?.value,
      },
    })
      .then(() => {
        fetchData();
        toast({
          title: "Update status Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose?.();
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Update status Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <AlertDialog
      size="lg"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          <div>Update Information Status for Match</div>
          <div>
            {`${matchDetail?.home_name || matchDetail?.localteam_title} vs ${
              matchDetail?.away_name || matchDetail?.visitorteam_title
            }`}
          </div>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form className="match-form">
            <div>
              <GridItem w="100%">
                <SelectController
                  control={control}
                  name="status"
                  label="Status"
                  isRequired
                  options={Object.keys(REQUEST_CRONJOB_STATUS).map((key) => (
                    {
                      label: REQUEST_CRONJOB_STATUS[key],
                      value: key,
                    }
                  ))}
                />
              </GridItem>
            </div>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            OK
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateStatusMatch;
