import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useClipboard,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { axiosPost } from "utils/api";
import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import ContentRegisterDialog from "./ContentRegisterDialog";
import PreviewVideo from "./PreviewVideo";
import {
  API_ROUTES,
  ContentType,
  ContentTypeVi,
  ROOT_API,
} from "utils/constant";
import capitalize from "lodash/capitalize";

const deleteContentApi = ROOT_API + API_ROUTES.DELETE_MEDIA;
const activeContentApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_CONTENT_ACTIVE;

function MediaRow(props) {
  const { data, isLast, fetchData } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const linkColor = useColorModeValue("blue.500", "blue");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const approvedColor = useColorModeValue("green.600", "green.900");
  const pendingColor = useColorModeValue("red.500", "red.900");
  const { onCopy, value, setValue, hasCopied } = useClipboard("");
  const toast = useToast();
  const {
    isOpen: isRegisterOpen,
    onOpen: onRegisterOpen,
    onClose: onRegisterClose,
  } = useDisclosure();

  const {
    isOpen: isPreviewVideoOpen,
    onOpen: onPreviewVideoOpen,
    onClose: onPreviewVideoClose,
  } = useDisclosure();

  function handleRowClick(value) {
    navigator.clipboard.writeText(`${ROOT_API}${value}`);
    toast({
      title: "Copied URL video",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  }

  async function handleDeleteClick() {
    if (window.confirm("Are you sure to delete the media?")) {
      const contentData = {
        fileIds: [data?._id],
      };
      try {
        const response = await axiosPost(deleteContentApi, contentData);
        if (response?.data?.code == 0) {
          toast({
            title: "Delete Media Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Delete Media Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }

  const getFileName = (path) => {
    if (path) {
      const fileName = path?.split("/")?.pop();
      return (
        <Button variant="unstyled" onClick={() => onPreviewVideoOpen()}>
          {fileName}
        </Button>
      );
    }
    return "";
  };
  return (
    <>
      <Tr>
        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
          minWidth={150}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {data?.filename}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {`${ROOT_API}${data?.path}`}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {ContentType[capitalize(data?.category)]}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          {/* <Button
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={onUploadOpen}
            mr={4}
          >
            <BiPhotoAlbum />
          </Button> */}
          <Button
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={() => handleRowClick(data?.path)}
            mr={4}
          >
            <CopyIcon />
          </Button>
          <Button
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={handleDeleteClick}
          >
            <DeleteIcon />
          </Button>
        </Td>
      </Tr>
      <ContentRegisterDialog
        isOpen={isRegisterOpen}
        onOpen={onRegisterOpen}
        onClose={onRegisterClose}
        fetchData={fetchData}
        data={data}
      />
      {data?.videoUrl && (
        <PreviewVideo
          isOpen={isPreviewVideoOpen}
          onOpen={onPreviewVideoOpen}
          onClose={onPreviewVideoClose}
          videoUrl={data.videoUrl}
        />
      )}
    </>
  );
}

export default MediaRow;
