import React, { useState, useEffect, useCallback } from "react";

import {
  Select,
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  useColorModeValue,
  Flex,
  Text,
} from "@chakra-ui/react";

import { axiosPost } from "../../utils/api";
import { SITE_URLS } from "utils/constant";
import { CopyIcon } from "@chakra-ui/icons";

const LinkMatchSeo = (props) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const textColor = useColorModeValue("gray.500", "white");

  const { isOpen, onOpen, onClose, match, cmt } = props;
  const handleCopyUrl = (matchUrl) => {
    navigator.clipboard.writeText(matchUrl);
  };
  const renderLink = useCallback(() => {
    const siteUrls = SITE_URLS;
    const data = siteUrls.map((i) => [`${i}tran-dau/${match.slug}`]);
    return data.map((i, id) => {
      if (cmt) {
        const link = `${i}?commentator=${cmt}`;
        return (
          <Flex key={id} justifyContent={"space-between"} alignItems="center">
            <Text>- {link}</Text>
            <Button px="2" bg="transparent" onClick={() => handleCopyUrl(link)}>
              <CopyIcon />
            </Button>
          </Flex>
        );
      }
      return (
        <Flex key={id} justifyContent={"space-between"} alignItems="center">
          <Text>{`${i}`}</Text>
          <Button px="2" bg="transparent" onClick={() => handleCopyUrl(i)}>
            <CopyIcon />
          </Button>
        </Flex>
      );
    });
  }, [cmt]);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="4xl"
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{`Link Trận Đấu: ${
            match?.home_name || match?.localteam_title
          } vs
            ${
              match?.away_name || match?.visitorteam_title
            }`}
            {cmt && <Text>{`BLV: ${cmt}`}</Text>}
            </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Flex flexDirection="column" rowGap={2}>
              {renderLink()}
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default LinkMatchSeo;
