import React, { useEffect } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { ValidateMessage } from "utils/constant";
import InputController from "components/Form/InputController";

const SettingFormModal = ({
  isEdit,
  settingDetail,
  isOpen,
  onOpen,
  onClose,
  fetchData,
}) => {
  const cancelRef = React.useRef();
  const toast = useToast();

  const [{ loading }, assignLinkApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.CREATE_MISSIONS,
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    missionName: yup.string().nullable().required(ValidateMessage.required),
    point: yup
      .number()
      .nullable()
      .typeError(ValidateMessage.mustNumber)
      .min(1, ValidateMessage.minNumber)
      .required(ValidateMessage.required),
    numberTime: yup
      .number()
      .nullable()
      .typeError(ValidateMessage.mustNumber)
      .min(1, ValidateMessage.minNumber)
      .required(ValidateMessage.required),
  });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      missionName: "",
      point: "",
      numberTime: "",
    },
  });

  useEffect(() => {
    reset({
      missionName: settingDetail?.missionName,
      point: settingDetail?.point,
      numberTime: settingDetail?.numberTime,
    });
  }, [settingDetail]);

  const onSubmit = (values) => {
    assignLinkApi({
      data: {
        ...values,
        type: "COUNTDOWN",
      },
    })
      .then(() => {
        toast({
          title: "Update setting Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose?.();
        fetchData?.();
      })
      .catch(() => {
        toast({
          title: "Update setting Fail",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <AlertDialog
        size="3xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {settingDetail ? "Update setting" : "Add setting"}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController
                control={control}
                name="missionName"
                label="Name"
                styleContainer={{ pt: "4" }}
                isRequired
              />
              <InputController
                type="number"
                control={control}
                name="numberTime"
                label="Time"
                isRequired
                styleContainer={{ pt: "4" }}
              />
              <InputController
                type="number"
                control={control}
                name="point"
                label="Point"
                isRequired
                styleContainer={{ pt: "4" }}
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={loading}
              disabled={!isDirty}
              onClick={handleSubmit(onSubmit)}
            >
              {settingDetail ? "Update" : "Add"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SettingFormModal;
