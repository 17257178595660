import React, { useEffect, useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  GridItem,
  Text,
  Flex,
} from "@chakra-ui/react";

const MatchCommentatorDetail = ({ matchDetail, isOpen, onClose }) => {
  const cancelRef = useRef();
  return (
    <AlertDialog
      size="lg"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          <div>Information detail commentator match</div>
          {matchDetail && (
            <Flex mt={2} flex>
              <Text whiteSpace="nowrap" fontWeight={400} pr={2}>
                Commentator name:
              </Text>
              <Text>
                {`${matchDetail?.commentators?.map((item) => item).join(", ")}`}
              </Text>
            </Flex>
          )}
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form className="match-form">
            <div>
              <GridItem w="100%">
               ssss
              </GridItem>
              <GridItem w="100%" mt={3}>
               sss
              </GridItem>
            </div>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default MatchCommentatorDetail;
