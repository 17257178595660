// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import useAxios from "axios-hooks";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useState, Fragment, useRef } from "react";
import Loading from "components/Layout/Loading";
import { TablePagination } from "@trendmicro/react-paginations";
import "@trendmicro/react-paginations/dist/react-paginations.css";
import MatchRow from "components/SelectedMatches/MatchRow";
import { initialFilter } from "utils/constant";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { today } from "utils/helpers";
import WeekFilter from "../WeekFilter/WeekFilter";
import LeagueFilter from "components/LeagueFilter/LeagueFilter";
import DatePicker from "components/DatePicker/DatePicker";
import { SearchIcon } from "@chakra-ui/icons";

const kolApi = process.env.REACT_APP_API_HOST + process.env.REACT_APP_KOLS;
const cdnLinkApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_CDN_LINKS;

const initialMatchesFilter = {
  ...initialFilter,
  status: undefined,
};
function SelectedMatches() {
  const bgColor = useColorModeValue("blue.200", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [filter, setFilter] = useState({
    ...initialMatchesFilter,
    date: today,
  });
  const [searchValue, setSearchValue] = useState("");
  const [matchTeam, setMatchTeam] = useState("");
  const [idMatch, setIdMatch] = useState("");
  const [status, setStatus] = useState({
    commentator: null,
    hot: null,
  });
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const refSearchButton = useRef(null);
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: ROOT_API + API_ROUTES.LIVE_MATCH_CDN,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  const [{ data: dataKols }] = useAxios(
    {
      url: kolApi,
    },
    {
      useCache: false,
    }
  );

  const [{ data: cndLinks }] = useAxios(
    {
      url: cdnLinkApi,
    },
    {
      useCache: false,
    }
  );

  const [{ data: userProfileData }] = useAxios(
    {
      url: ROOT_API + API_ROUTES.USER_PROFILE,
    },
    {
      useCache: false,
    }
  );

  // const [status, setStatus] = useState(undefined);

  const onFilterMatch = (dataFilter) => {
    setFilter({
      ...filter,
      pageIndex: 0,
      date: dataFilter,
      // status
    });
    // refetch();
    // setCurrentPage(1);
  };

  const onFilterLeague = (dataFilter) => {
    setFilter({
      ...filter,
      pageIndex: 0,
      league_id: dataFilter,
    });
  };

  const onChangeDate = (type) => (date) => {
    setDateFilter((prev) => ({
      ...prev,
      ...(type === "startDate" && { endDate: null }),
      [type]: date,
    }));
  };

  const resetSearchKeywords = () => {
    setDateFilter({
      startDate: null,
      endDate: null,
    });
    setIdMatch("");
    setMatchTeam("");
    setSearchValue("");
    refetch({
      params: {
        ...filter,
        startDate: null,
        endDate: null,
      },
    });
    setStatus({
      commentator: '',
      hot: '',
    })
  };

  const handleSearch = () => {
    refetch({ params: { ...filter, ...dateFilter } });
  };
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Livestream Matches
          </Text>
          <Flex
            flexWrap="wrap"
            alignItems={"end"}
            marginTop={4}
            marginBottom={2}
            gap={"10px"}
          >
            <FormControl
              display="flex"
              flexDirection={"column"}
              width={{ base: "full", sm: "300px" }}
              maxW="300px"
            >
              <FormLabel
                m="0"
                fontSize="sm"
                color={textColor}
                fontWeight="bold"
              >
                Start Date
              </FormLabel>
              <DatePicker
                selectedDate={dateFilter.startDate}
                minDate={
                  new Date(new Date().setMonth(new Date().getMonth() - 1))
                }
                maxDate={new Date()}
                onChange={(date) => onChangeDate("startDate")(date)}
              />
            </FormControl>
            <FormControl
              display="flex"
              flexDirection={"column"}
              width={{ base: "full", sm: "300px" }}
              maxW="300px"
            >
              <FormLabel
                m="0"
                fontSize="sm"
                color={textColor}
                fontWeight="bold"
              >
                End Date
              </FormLabel>
              <DatePicker
                selectedDate={dateFilter.endDate}
                minDate={
                  new Date(new Date().setMonth(new Date().getMonth() - 1))
                }
                maxDate={new Date()}
                onChange={(date) => onChangeDate("endDate")(date)}
              />
            </FormControl>
            <FormControl
              display="flex"
              flexDirection={"column"}
              width={{ base: "full", sm: "300px" }}
              maxW="300px"
            >
              <FormLabel
                m="0"
                fontSize="sm"
                color={textColor}
                fontWeight="bold"
              >
                Commentator
              </FormLabel>
              <InputGroup>
                <InputRightElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputRightElement>
                <Input
                  type="text"
                  variant="outline"
                  placeholder="Search by commentator"
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                  onKeyUp={(event) => {
                    if (event.key === "Enter" && event?.target?.value) {
                      const searchBtn = refSearchButton?.current;
                      if (searchBtn !== null) {
                        searchBtn.click();
                      }
                    }
                  }}
                />
              </InputGroup>
            </FormControl>
            <FormControl width={{ base: "full", sm: "300px" }}>
              <FormLabel h={"21px"} fontWeight={600} fontSize={14} m={0}>
                Comment status
              </FormLabel>
              <Select
                placeholder="Select comment status"
                isClearable
                menuShouldBlockScroll
                value={status.commentator}
                onChange={(e) => {
                  setStatus({
                    ...status,
                    commentator: e.target.value,
                  });
                }}
              >
                <option value={0}>All</option>
                <option value={1}>Commentary match</option>
                <option value={2}>Match without comment</option>
              </Select>
            </FormControl>
            <FormControl width={{ base: "full", sm: "300px" }}>
              <FormLabel h={"21px"} fontWeight={600} fontSize={14} m={0}>
                Hot status
              </FormLabel>
              <Select
                placeholder="Select hot status"
                isClearable
                menuShouldBlockScroll
                value={status.hot}
                onChange={(e) => {
                  setStatus({
                    ...status,
                    hot: e.target.value,
                  });
                }}
              >
                <option value={0}>All</option>
                <option value={1}>Hot</option>
                <option value={2}>Not hot</option>
              </Select>
            </FormControl>
            <FormControl
              display="flex"
              flexDirection={"column"}
              width={{ base: "full", sm: "300px" }}
              maxW="300px"
            >
              <FormLabel
                m="0"
                fontSize="sm"
                color={textColor}
                fontWeight="bold"
              >
                Match team
              </FormLabel>
              <InputGroup>
                <InputRightElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputRightElement>
                <Input
                  type="text"
                  variant="outline"
                  placeholder="Search by match team"
                  value={matchTeam}
                  onChange={(event) => setMatchTeam(event.target.value)}
                  onKeyUp={(event) => {
                    if (event.key === "Enter" && event?.target?.value) {
                      const searchBtn = refSearchButton?.current;
                      if (searchBtn !== null) {
                        searchBtn.click();
                      }
                    }
                  }}
                />
              </InputGroup>
            </FormControl>
            <FormControl
              display="flex"
              flexDirection={"column"}
              width={{ base: "full", sm: "300px" }}
              maxW="300px"
            >
              <FormLabel
                m="0"
                fontSize="sm"
                color={textColor}
                fontWeight="bold"
              >
                Id match
              </FormLabel>
              <InputGroup>
                <InputRightElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputRightElement>
                <Input
                  type="text"
                  variant="outline"
                  placeholder="Search by Id match"
                  value={idMatch}
                  onChange={(event) => setIdMatch(event.target.value)}
                  onKeyUp={(event) => {
                    if (event.key === "Enter" && event?.target?.value) {
                      const searchBtn = refSearchButton?.current;
                      if (searchBtn !== null) {
                        searchBtn.click();
                      }
                    }
                  }}
                />
              </InputGroup>
            </FormControl>
            <Button colorScheme="blue" onClick={handleSearch}>
              Search
            </Button>
            <Button onClick={resetSearchKeywords}>Reset</Button>
          </Flex>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loading />
          ) : (
            <Stack overflow={"auto"}>
              <WeekFilter onFilterMatch={onFilterMatch} filterProps={filter} />
              {/* <LeagueFilter
                onFilterLeague={onFilterLeague}
                filterProps={filter}
              /> */}
              <Stack overflow={"auto"}>
                <Table variant="simple" color={textColor}>
                  <Thead bg={bgColor} hidden>
                    <Tr my=".8rem" pl="0px" color={textColor}>
                      <Th borderColor={borderColor} color={textColor}>
                        League
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.matches?.length ? (
                      data.matches.map((row, index, arr) => {
                        return (
                          <Fragment key={index}>
                            <Tr>
                              <Td
                                borderColor={borderColor}
                                borderBottom={
                                  index === arr.length - 1 ? "none" : null
                                }
                                // colSpan={6}
                                bg={borderColor}
                              >
                                <Text
                                  fontSize="md"
                                  color={textColor}
                                  fontWeight="bold"
                                >
                                  {row.league_name}
                                </Text>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td px={0} py={0}>
                                <Table>
                                  <Thead>
                                    <Tr
                                      py="1rem"
                                      pl="0px"
                                      color={textColor}
                                      bg={bgColor}
                                    >
                                      <Th
                                        borderColor={borderColor}
                                        color={textColor}
                                      >
                                        ID Match
                                      </Th>
                                      <Th
                                        borderColor={borderColor}
                                        color={textColor}
                                      >
                                        Home Team / Away Team
                                      </Th>
                                      <Th
                                        borderColor={borderColor}
                                        color={textColor}
                                      >
                                        Time
                                      </Th>
                                      <Th
                                        borderColor={borderColor}
                                        color={textColor}
                                      >
                                        Status
                                      </Th>
                                      <Th
                                        borderColor={borderColor}
                                        color={textColor}
                                      >
                                        Result
                                      </Th>
                                      <Th
                                        borderColor={borderColor}
                                        color={textColor}
                                      >
                                        Livestream Match
                                      </Th>
                                      <Th
                                        borderColor={borderColor}
                                        color={textColor}
                                      >
                                        Feature Match
                                      </Th>
                                      <Th
                                        borderColor={borderColor}
                                        color={textColor}
                                      >
                                        Add Commentator
                                      </Th>
                                      <Th
                                        borderColor={borderColor}
                                        color={textColor}
                                      >
                                        Action
                                      </Th>
                                    </Tr>
                                  </Thead>
                                  {row?.matches?.length
                                    ? row.matches.map(
                                        (row_child, _index, _arr) => (
                                          <Tbody key={row_child._id}>
                                            <MatchRow
                                              data={row_child}
                                              fetchData={refetch}
                                              isLast={
                                                _index === _arr.length - 1
                                                  ? true
                                                  : false
                                              }
                                              cndLinks={cndLinks}
                                              dataKols={dataKols}
                                              isSelected={true}
                                              userRole={
                                                userProfileData?.data?.role
                                              }
                                            />
                                          </Tbody>
                                        )
                                      )
                                    : ""}
                                </Table>
                              </Td>
                            </Tr>
                          </Fragment>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td
                          borderColor={borderColor}
                          // colSpan={6}
                          bg={borderColor}
                          py={10}
                        >
                          <Text
                            fontSize="md"
                            color={textColor}
                            fontWeight="bold"
                            textAlign={"center"}
                          >
                            No Data
                          </Text>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </Stack>
              <Flex justifyContent="flex-end">
                <TablePagination
                  type="full"
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={({ page, pageLength }) => {
                    // console.log(page);
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                  prevPageRenderer={() => <i className="fa fa-angle-left" />}
                  nextPageRenderer={() => <i className="fa fa-angle-right" />}
                />
              </Flex>
            </Stack>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default SelectedMatches;
