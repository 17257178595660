import React, { useState, useEffect, Fragment } from "react";

import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  useColorModeValue,
  Flex,
  List,
  ListItem,
  Divider,
  FormLabel,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";

import { axiosPost } from "../../utils/api";
import { API_ROUTES, ROOT_API } from "utils/constant";
import useAxios from "axios-hooks";

const ChooseCdnLinkDialog = (props) => {
  const { isOpen, onOpen, onClose, fetchData, dataCurrentMatch } = props;
  const cancelRef = React.useRef();
  const toast = useToast();
  const textColor = useColorModeValue("gray.500", "white");
  const [selectedCdn, setSelectedCdn] = useState(0);
  const [loading, setLoading] = useState(false);

  const [{ data: userProfileData }] = useAxios(
    {
      url: ROOT_API + API_ROUTES.USER_PROFILE,
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    if (dataCurrentMatch?.linkCommentatorFlv) {
      setSelectedCdn(dataCurrentMatch.linkCommentatorFlv);
    }
  }, [dataCurrentMatch]);

  const clickChooseCdnButton = async () => {
    setLoading(true);
    const dataUpdate = {
      matchId: dataCurrentMatch?.matchId,
      link: selectedCdn,
      // cdnId: selectedCdn,
    };
    try {
      const data = await axiosPost(
        ROOT_API + API_ROUTES.SET_LINK_MATCH_CDN,
        dataUpdate
      );
      if (
        data?.data?.code == 0 ||
        data?.status === 200 ||
        data?.status === 201
      ) {
        toast({
          title: "Choose CDN Link Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        fetchData();
        onClose();
      }
      setLoading(false);
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Choose CDN Link Fail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };
  const onSelectCdn = (value) => {
    setSelectedCdn(value);
  };

  const setNoLive = async () => {
    if (window.confirm("Are you sure your match settings aren't live?")) {
      const dataUpdate = {
        matchId: dataCurrentMatch?.matchId,
        noLive: true,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.STOP_LIVE_MATCH_CDN,
          dataUpdate
        );
        if (response?.data?.code == 0) {
          toast({
            title: "Set No Live Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Set No Live Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="xl"
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Choose CDN Link To Use</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <List spacing={3}>
              <RadioGroup
                onChange={onSelectCdn}
                value={selectedCdn}
                name="kol-radio"
              >
                {userProfileData?.data?.cdnLinks?.length
                  ? userProfileData?.data?.cdnLinks.map((link, index) => (
                      <Fragment key={index}>
                        <Flex direction={"row"} columnGap={4} m={2} w="full">
                          <Radio value={link} id={`r-${link}`} />
                          <FormLabel htmlFor={`r-${link}`} w={"full"} m={0}>
                            <Flex
                              direction={"row"}
                              columnGap={4}
                              m={2}
                              cursor={"pointer"}
                            >
                              <ListItem color={textColor} wordBreak="break-all">
                                {link}
                              </ListItem>
                            </Flex>
                          </FormLabel>
                        </Flex>
                        <Divider />
                      </Fragment>
                    ))
                  : ""}
              </RadioGroup>
            </List>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
                setSelectedCdn(null);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                clickChooseCdnButton();
              }}
              disabled={!selectedCdn || loading}
            >
              Live
            </Button>
            {dataCurrentMatch?.isLive && (
              <Button colorScheme="red" ml={3} onClick={setNoLive}>
                Stop Live
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ChooseCdnLinkDialog;
