import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Flex,
  Text,
  Box,
  Avatar,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import moment from "moment";
import InputController from "components/Form/InputController";
import {
  FileImageValid,
  ValidateMessage,
  ROOT_API,
  API_ROUTES,
} from "utils/constant";
import DatePickerController from "components/Form/DatePickerController";
import "./style.css";
import SelectController from "components/Form/SelectController";
import { mappingOptionSelect } from "utils/mapping";

const CreateMatchDialog = ({ isOpen, matchDetail, onClose, fetchData }) => {
  const cancelRef = useRef();
  const toast = useToast();
  const [leagueOption, setLeagueOption] = useState([]);
  const [{ data: miniLeagueData }] = useAxios(
    {
      url: ROOT_API + API_ROUTES.LEAGUES_FILTER,
      params: { isActive: true },
    },
    {
      useCache: false,
    }
  );
  const [{ loading: createLoading }, createMatchApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.CREATE_MATCH_CDN_MANUAL,
    },
    { manual: true }
  );

  useEffect(
    () => () => {
      reset();
    },
    []
  );

  useEffect(() => {
    setLeagueOption(
      mappingOptionSelect(miniLeagueData?.data, "name", "leagueId")
    );
  }, [miniLeagueData]);

  const schema = yup.object().shape({
    home_name: yup.string().required(ValidateMessage.required),
    away_name: yup.string().required(ValidateMessage.required),
    time: yup.string().nullable().required(ValidateMessage.required),
    leagueId: yup.object().nullable().required(ValidateMessage.required),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      home_name: "",
      away_name: "",
      home_logo: "",
      away_logo: "",
    },
  });

  const handleSuccess = () => {
    fetchData();
    toast({
      title: `${matchDetail?._id ? "Edit" : "Create"} Match Successfully`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    onClose?.();
  };

  const handleError = (error) => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${matchDetail?._id ? "Edit" : "Create"} Match Fail`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = (dataForm) => {
    createMatchApi({
      data: {
        ...dataForm,
        leagueId: dataForm.leagueId.value,
        time: moment(dataForm.time).unix(),
      },
    })
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <AlertDialog
      size="lg"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Create Match</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form className="match-form">
            <DatePickerController
              isRequired
              styleContainer={{ pb: 2 }}
              control={control}
              name="time"
              label="Time"
              showTimeSelect={true}
              dateFormat="yyyy-MM-dd HH:mm"
              timeFormat="HH:mm"
              timeIntervals={5}
            />
            <SelectController
              styleContainer={{ pb: 2 }}
              control={control}
              isRequired
              name="leagueId"
              label="League"
              options={leagueOption}
            />
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem w="100%">
                <InputController
                  control={control}
                  name="home_name"
                  label="Home name"
                  isRequired
                  styleContainer={{ pb: 2 }}
                />
                <InputController
                  control={control}
                  name="home_logo"
                  label="Home logo"
                  styleContainer={{ pb: 2 }}
                />
              </GridItem>
              <GridItem w="100%">
                <InputController
                  control={control}
                  name="away_name"
                  label="Away name"
                  isRequired
                  styleContainer={{ pb: 2 }}
                />
                <InputController
                  control={control}
                  name="away_logo"
                  label="Away logo"
                  styleContainer={{ pb: 2 }}
                />
              </GridItem>
            </Grid>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            isLoading={createLoading}
            onClick={handleSubmit(onSubmit)}
          >
            OK
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateMatchDialog;
