

import React, { useEffect, useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  GridItem,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidateMessage } from "utils/constant";
import InputController from "components/Form/InputController";
import { API_ROUTES } from "utils/constant";
import { ROOT_API } from "utils/constant";
import useAxios from "axios-hooks";

const UpdateResultMatch = ({ matchDetail, isOpen, onClose, fetchData }) => {
  const cancelRef = useRef();
  const toast = useToast();
  const [{ loading }, updateResultApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.UPDATE_SCORE,
    },
    { manual: true }
  );

  useEffect(() => {
    reset({
        homeGoals: matchDetail?.homeGoals,
        awayGoals: matchDetail?.awayGoals,
    });
  }, [matchDetail]);

  const schema = yup.object().shape({
    homeGoals: yup.string().nullable().required(ValidateMessage.required),
    awayGoals: yup.string().nullable().required(ValidateMessage.required),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      homeGoals: matchDetail?.homeGoals || "",
      awayGoals: matchDetail?.awayGoals || "",
    },
  });

  const onSubmit = (dataForm) => {
    updateResultApi({
      data: {
        homeGoals: Number(dataForm?.homeGoals),
        awayGoals: Number(dataForm?.awayGoals),
        matchId: matchDetail?.matchId,
      },
    })
      .then(() => {
        fetchData();
        toast({
          title: "Update Result Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose?.();
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Update Result Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <AlertDialog
      size="lg"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          <div>Update Information Result Match</div>
          <div>
            {`${matchDetail?.homeGoals || matchDetail?.localteam_title} vs ${
              matchDetail?.awayGoals || matchDetail?.visitorteam_title
            }`}
          </div>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form className="match-form">
            <div>
              <GridItem w="100%">
                <InputController
                  type="number"
                  control={control}
                  name="homeGoals"
                  label="Home Goals"
                  isRequired
                />
              </GridItem>
              <GridItem w="100%">
                <InputController
                  type="number"
                  control={control}
                  name="awayGoals"
                  label="Away Goals"
                  isRequired
                />
              </GridItem>
            </div>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            OK
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateResultMatch;
