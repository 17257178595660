import React, { useState, useEffect, useMemo } from "react";

import {
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Avatar,
  useToast,
  Text,
  AspectRatio,
} from "@chakra-ui/react";

import { axiosPost } from "../../utils/api";
import { get } from "lodash";
import { LINK_REGEX, MediaTypeOption } from "utils/constant";
import { FaFilm, FaPhotoVideo, FaVideo } from "react-icons/fa";
import { MAX_VIDEO_UPLOAD } from "utils/constant";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { Select } from "chakra-react-select";

const createContentApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_CONTENT;

const updateContentApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_CONTENT_UPDATE;

const mediaUrl = process.env.REACT_APP_API_HOST;

const UploadVideo = ({ isOpen, onOpen, onClose, fetchData, data }) => {
  const cancelRef = React.useRef();
  const fileInput = React.useRef(null);

  const [fileName, setFileName] = useState("");
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [type, setType] = useState();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (data) {
      const imageName = data?.thumbnailUrl
        ? data.thumbnailUrl.split("/").pop()
        : "";
      setFileName(imageName);
      if (data?.thumbnailUrl) {
        setImagePreview(ROOT_API + data.thumbnailUrl);
      }
    }
  }, [data]);

  const clickUpdateButton = async () => {
    if (!type) {
      toast({
        title: "Type is require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (!image) {
      toast({
        title: "Video File is require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const contentData = new FormData();
    if (image) {
      contentData.append("videoFile", image);
    }
    if (name) {
      contentData.append("name", name);
    }
    const headers = {
      "content-type": "multipart/form-data",
    };
    setLoading(true);
    try {
      const response = await axiosPost(
        `${ROOT_API}${API_ROUTES.UPLOAD_VIDEO_MEDIA}?category=${type?.value}`,
        contentData,
        headers
      );
      if (response?.data?.code == 0) {
        toast({
          title: "Upload Video Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        fetchData();
        onClose();
        setFileName("");
        setImage(null);
        setType("");
      }
    } catch (error) {
      console.log(error);
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Upload Video Fail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  const onChangeImage = () => {
    const btn = fileInput?.current;
    if (btn !== null) {
      btn.click();
    }
  };

  const onCloseDialog = () => {
    if (!data) {
      setFileName("");
      setImage(null);
      setType("");
    }

    onClose();
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{"Upload Video"}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Type</FormLabel>
              <Select
                value={type}
                onChange={(selected) => setType(selected)}
                options={MediaTypeOption}
                placeholder={"Choose"}
                chakraStyles={{
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 10,
                  }),
                }}
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>
                Video (Click on the thumbnail default below to change)
              </FormLabel>
              <Avatar
                src={imagePreview}
                cursor={"pointer"}
                w="200px"
                h="100px"
                borderRadius="4px"
                me="18px"
                icon={<FaPhotoVideo fontSize="1.5rem" />}
                mb={3}
                onClick={onChangeImage}
              />
              {fileName && <Text>{fileName}</Text>}
              <Input
                hidden
                type="file"
                ref={fileInput}
                accept="video/*"
                onChange={(event) => {
                  if (event.target.files.length === 0) return;
                  const fileImage = event.target.files[0];
                  const regex = /\.(mp4)$/i;
                  if (!regex.test(fileImage?.name)) {
                    toast({
                      title: "Only video file mp4 uploading is supported",
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                    return;
                  }
                  const fsMb = fileImage.size / (1024 * 1024);
                  const MAX_FILE_SIZE = 1000;
                  if (fsMb > MAX_FILE_SIZE) {
                    toast({
                      title: "Max file size 1GB",
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                    return;
                  }
                  setImage(fileImage);
                  const thumbnail = URL.createObjectURL(event.target.files[0]);
                  setImagePreview(thumbnail);
                  setFileName(fileImage?.name);
                }}
              />
              {image && (
                <AspectRatio maxW="300px" ratio={1}>
                  <iframe title="video" src={imagePreview} allowFullScreen />
                </AspectRatio>
              )}
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseDialog}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => clickUpdateButton()}
              disabled={!data && !image && !type}
              isLoading={loading}
            >
              Upload
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UploadVideo;
