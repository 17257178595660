import React, { useEffect } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { ValidateMessage } from "utils/constant";
import InputController from "components/Form/InputController";

const AddCdnLinkCustomDialog = ({
  isEdit,
  data,
  isOpen,
  onOpen,
  onClose,
  fetchData,
}) => {
  const cancelRef = React.useRef();
  const toast = useToast();

  const [{ loading: assignLoading }, assignLinkApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.UPDATE_LICK_CDN,
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    rtmp: yup.string().nullable().required(ValidateMessage.required),
    cdnM3u8Link: yup.string().nullable().required(ValidateMessage.required),
  });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rtmp: "",
      cdnM3u8Link: "",
    },
  });

  useEffect(() => {
    reset({
      rtmp: data?.rtmp,
      cdnM3u8Link: data?.cdnM3u8Link,
      streamKey: data?.streamKey,
    });
  }, [data]);

  const onSubmit = (values) => {
    assignLinkApi({
      data: {
        rtmp: values?.rtmp,
        cdnM3u8Link: values?.cdnM3u8Link,
        username: data?.name,
      },
    })
      .then(() => {
        toast({
          title: "Add Link Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose?.();
        fetchData?.();
      })
      .catch(() => {
        toast({
          title: "Add Link Fail",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <AlertDialog
        size="3xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {isEdit ? "Add CDN Link" : "CDN Info"}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              {data?.streamKey && (
                <InputController
                  control={control}
                  name="streamKey"
                  label="Stream Key"
                  isRequired
                  disabled={true}
                />
              )}
              <InputController
                control={control}
                name="rtmp"
                label="RTMP"
                styleContainer={{ pt: "4" }}
                isRequired
                disabled={!isEdit}
              />
              <InputController
                control={control}
                name="cdnM3u8Link"
                label="CDN Link"
                styleContainer={{ pt: "4" }}
                isRequired
                disabled={!isEdit}
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            {isEdit && (
              <>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  ml={3}
                  isLoading={assignLoading}
                  disabled={!isDirty}
                  onClick={handleSubmit(onSubmit)}
                >
                  Add
                </Button>
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddCdnLinkCustomDialog;
