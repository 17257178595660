import React, { useEffect, useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidateMessage } from "utils/constant";
import InputController from "components/Form/InputController";
import { API_ROUTES } from "utils/constant";
import { ROOT_API } from "utils/constant";
import useAxios from "axios-hooks";
import moment from "moment-timezone";
import DatePickerController from "components/Form/DatePickerController";

const UpdateTimeDialog = ({ matchDetail, isOpen, onClose, fetchData }) => {
  const cancelRef = useRef();
  const toast = useToast();
  const [{ loading }, updateTimeApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.UPDATE_TIME,
    },
    { manual: true }
  );

  useEffect(() => {
    reset({
      time: matchDetail?.startTimez
        ? new Date(moment(matchDetail?.startTimez).format("YYYY-MM-DD HH:mm"))
        : undefined,
      home_name: matchDetail?.home_name,
      away_name: matchDetail?.away_name,
    });
  }, [matchDetail]);

  const schema = yup.object().shape({
    time: yup.string().nullable().required(ValidateMessage.required),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      time: null, 
      home_name: matchDetail?.home_name || "",
      away_name: matchDetail?.away_name || "",
    },
  });

  const onSubmit = (dataForm) => {
    updateTimeApi({
      data: {
        ...dataForm,
        matchId: matchDetail?.matchId,
        time: moment(dataForm?.time).valueOf() / 1000,
      },
    })
      .then(() => {
        fetchData();
        toast({
          title: "Update Time Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose?.();
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Update Time Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <AlertDialog
      size="lg"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          <div>Update Information</div>
          <div>
            {`${matchDetail?.home_name || matchDetail?.localteam_title} vs ${
              matchDetail?.away_name || matchDetail?.visitorteam_title
            }`}
          </div>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form className="match-form">
            <div>
              <GridItem w="100%">
                <InputController
                  type="text"
                  control={control}
                  name="home_name"
                  label="Home name"
                  isRequired
                />
              </GridItem>
              <GridItem w="100%">
                <InputController
                  type="text"
                  control={control}
                  name="away_name"
                  label="Away name"
                  isRequired
                />
              </GridItem>
              <DatePickerController
                isRequired
                styleContainer={{ pt: "4", pb: 2 }}
                control={control}
                name="time"
                label="Time"
                showTimeSelect={true}
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                timeIntervals={5}
              />
            </div>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            OK
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateTimeDialog;
