import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Flex,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";

function Row(props) {
  const { row, isLast, fetchData } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.type}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.note?.note}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Stack direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              League: {row?.note?.match?.league_title}
            </Text>
            <Text fontSize="md" color={textColor} fontWeight="bold">
              Time: {row?.note?.match?.startTime}
            </Text>
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.note?.match?.localteam_title} -{" "}
              {row?.note?.match?.visitorteam_title}
            </Text>
          </Stack>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.user?.name || row?.user?.username}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {moment.utc(row?.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}
            </Text>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}

export default Row;
