import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { axiosPost } from "utils/api";
import { API_ROUTES, ROOT_API } from "utils/constant";
import SettingFormModal from "./SettingFormModal";

function Row(props) {
  const { row, isLast, fetchData } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleActiveBookmaker = async () => {
    if (window.confirm("Are you sure to active this bookmaker?")) {
      const data = {
        bookmakerId: row._id,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.SET_PRIORITY_BOOKMAKERS,
          data
        );

        if (response?.data?.code === 0) {
          toast({
            title: "Update Bookmaker Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Update Bookmaker Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const handleEditSetting = () => {
    onOpen();
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.missionName}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex flexDirection={"row"} alignItems={"center"} columnGap={2}>
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.point}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <IconButton onClick={handleEditSetting}>
            <Text fontSize="md" color="blue" fontWeight="bold" cursor="pointer">
              <EditIcon />
            </Text>
          </IconButton>
        </Td>
      </Tr>
      <SettingFormModal
        settingDetail={row}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        fetchData={fetchData}
      />
    </>
  );
}

export default Row;
