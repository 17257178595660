import React, { useEffect } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { ValidateMessage } from "utils/constant";
import InputController from "components/Form/InputController";

export default function KolPriorityDialog({
  isEdit,
  data,
  isOpen,
  onOpen,
  onClose,
  kolData,
  fetchData,
}) {
  const cancelRef = React.useRef();
  const toast = useToast();

  const [{ loading: assignLoading }, assignLinkApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.SET_ORDER_COMMENTATOR,
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    order: yup
      .number()
      .nullable()
      .typeError(ValidateMessage.mustNumber)
      .min(1, ValidateMessage.minNumber)
      .required(ValidateMessage.required),
  });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      order: 1,
    },
  });

  useEffect(() => {
    reset({
      order: data?.order,
    });
  }, [data]);

  const onSubmit = (values) => {
    const orderExists = kolData?.find((item) => item?.order === values?.order);

    if (!!orderExists) {
      toast({
        title: "Ordinal number exists",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    assignLinkApi({
      data: {
        ...values,
        username: data?.name,
      },
    })
      .then(() => {
        toast({
          title: "Update ordinal number Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose?.();
        fetchData?.();
      })
      .catch(() => {
        toast({
          title: "Update ordinal number Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <AlertDialog
        size="3xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{`Update ordinal number commentator: ${data?.name}`}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController
                type="number"
                control={control}
                name="order"
                label="Ordinal number"
                isRequired
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={assignLoading}
              disabled={!isDirty}
              onClick={handleSubmit(onSubmit)}
            >
              Update
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
