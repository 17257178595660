import React, { useState, useEffect } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  FormLabel,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { CloseIcon } from "@chakra-ui/icons";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import SelectController from "components/Form/SelectController";
import { ValidateMessage } from "utils/constant";

const AddLinkDialog = ({ data, isOpen, onOpen, onClose, fetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [cdnLinkOptions, setCdnLinkOptions] = useState([]);

  const [{ data: cndLinks }] = useAxios(
    {
      url: ROOT_API + API_ROUTES.GET_CDN_LINK,
    },
    {
      useCache: false,
    }
  );
  const [{ loading: assignLoading }, assignLinkApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.ASSIGN_CDN_LINK,
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    links: yup.array().of(
      yup.object().shape({
        link: yup.object().nullable().required(ValidateMessage.required),
      })
    ),
  });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      links: [{ link: undefined }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "links",
  });

  useEffect(() => {
    setCdnLinkOptions(
      cndLinks?.data?.map((item, index) => ({
        label: `Link ${index + 1}: ${item.link}`,
        value: item.link,
      }))
    );
  }, [cndLinks?.data]);

  useEffect(() => {
    reset({
      links: data?.cdnLinks?.map((item) => {
        const linkExists = cdnLinkOptions?.find(
          (itemLinkOption) => itemLinkOption?.value === item
        );

        if (linkExists) {
          return {
            link: linkExists,
          };
        }
      }),
    });
  }, [data?.cdnLinks, cdnLinkOptions]);

  const onSubmit = (values) => {
    assignLinkApi({
      data: {
        username: data?.name,
        links: values?.links?.map((item) => item?.link?.value),
      },
    })
      .then(() => {
        toast({
          title: "Assign Link Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose?.();
        fetchData?.();
      })
      .catch(() => {
        toast({
          title: "Assign Link Fail",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <AlertDialog
        size="3xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>ĐĂNG KÝ KOL</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormLabel minW="150px">Link</FormLabel>
            <Flex flexDirection="column" gap="2">
              {fields.map((field, index) => (
                <Flex alignItems="center" gap="2">
                  <SelectController
                    control={control}
                    name={`links.${index}.link`}
                    isRequired
                    options={cdnLinkOptions}
                  />
                  <IconButton
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <CloseIcon fontSize="14" />
                  </IconButton>
                </Flex>
              ))}
            </Flex>
            <Button
              mt="2"
              p="2"
              maxH="30px"
              fontSize="12px"
              colorScheme="blue"
              onClick={() => {
                append({ link: undefined });
              }}
            >
              Add link
            </Button>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={assignLoading}
              disabled={!isDirty}
              onClick={handleSubmit(onSubmit)}
            >
              Assign
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddLinkDialog;
