import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Button,
  Text,
  useColorModeValue,
  Box,
  Grid,
  GridItem,
  useToast,
  Input,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import { isEmpty } from "lodash";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { ROOT_API, ValidateMessage } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { FileImageValid } from "utils/constant";
import { getKeyByValue } from "utils/helpers";
import { checkDataChanged } from "utils/helpers";
import FileSelect from "components/Form/FileSelectController";
import InputController from "components/Form/InputController";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Position = {
  Left: "LEFT",
  Left_1: "LEFT_1",
  Right: "RIGHT",
  Right_1: "RIGHT_1",
  Top1: "TOP_1",
  Top2: "TOP_2",
};

const PositionKey = {
  [Position.Left]: "bannerLeftFile",
  [Position.Left_1]: "bannerLeftFile1",
  [Position.Right]: "bannerRightFile",
  [Position.Right_1]: "bannerRightFile1",
  [Position.Top1]: "bannerTop1File",
  [Position.Top2]: "bannerTop2File",
};

const BannerAds = () => {
  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "white");
  const [fileSelected, setFileSelected] = useState({
    bannerLeftFile: null,
    bannerLeftFile1: null,
    bannerRightFile: null,
    bannerRightFile1: null,
    bannerTop1File: null,
    bannerTop2File: null,
  });
  const [error, setError] = useState({
    bannerLeftFile: null,
    bannerLeftFile1: null,
    bannerRightFile: null,
    bannerRightFile1: null,
    bannerTop1File: null,
    bannerTop2File: null,
  });
  const [textMarquee, setTextMarquee] = useState('')
  const isDisableSaveBtn = useMemo(() => checkDataChanged(fileSelected), [
    fileSelected,
  ]);
  const schema = yup.object().shape({
    linkLeft: yup.string().nullable().required(ValidateMessage.required),
    linkRight: yup.string().nullable().required(ValidateMessage.required),
    linkLeft1: yup.string().nullable().required(ValidateMessage.required),
    linkRight1: yup.string().nullable().required(ValidateMessage.required),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      linkLeft: "",
      linkRight: "",
      linkLeft1: "",
      linkRight1: "",
    },
  });

  const [{ loading, data }, refetch] = useAxios({
    url: `${ROOT_API}${API_ROUTES.UPLOAD_BANNER_ADS}`,
  });
  const [{ data: marquee }, refetchMarquee] = useAxios({
    url: `${ROOT_API}${API_ROUTES.MARQUEE}`,
  });
  const [{ loading: uploading }, uploadAdsApi] = useAxios(
    {
      method: "post",
      url: `${ROOT_API}${API_ROUTES.UPLOAD_BANNER_ADS}`,
    },
    { manual: true }
  );
  const [{ loading: loadingMarquee }, saveMarqueeApi] = useAxios(
    {
      method: "post",
      url: `${ROOT_API}${API_ROUTES.MARQUEE}`,
    },
    { manual: true }
  );
  const [{ loading: deleting }, deleteAdsApi] = useAxios(
    {
      method: "post",
      url: `${ROOT_API}${API_ROUTES.DELETE_BANNER_ADS}`,
    },
    { manual: true }
  );

  useEffect(() => {
    if (!isEmpty(data?.banners)) {
      const banners = {};

      data?.banners?.forEach((item) => {
        const key = PositionKey[item.position];
        if (key === "bannerLeftFile") {
          setValue("linkLeft", item?.link);
        }
        if (key === "bannerRightFile") {
          setValue("linkRight", item?.link);
        }
        if (key === "bannerLeftFile1") {
          setValue("linkLeft1", item?.link);
        }
        if (key === "bannerRightFile1") {
          setValue("linkRight1", item?.link);
        }
        banners[key] = {
          filePreview: item?.bannerUrl
            ? process.env.REACT_APP_API_HOST + item?.bannerUrl
            : null,
        };
      });

      setFileSelected(banners);
    }
    if (!isEmpty(marquee?.data)) {
      setTextMarquee(marquee?.data)
    }
  }, [data?.banners]);

  const handleFileSelect = (fieldName, e) => {
    if (e?.target?.files?.[0]) {
      const fileSelected = e.target.files[0];
      const filePreview = URL.createObjectURL(fileSelected);
      const extensionFile = fileSelected?.name
        ?.split(".")
        ?.pop()
        ?.toLowerCase();

      if ([...FileImageValid, "gif"].includes(extensionFile)) {
        setFileSelected((prev) => ({
          ...prev,
          [fieldName]: { file: fileSelected, filePreview },
        }));

        return;
      }

      setFileSelected((prev) => ({
        ...prev,
        [fieldName]: null,
      }));
      handleErrorFile(
        fieldName,
        "File reload formats are supported only .png, .jpeg, .jpg"
      );
    }
  };

  const handleErrorFile = (fieldName, value) => {
    setError((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleRemoveFile = (fieldName) => {
    const keyName = getKeyByValue(PositionKey, fieldName);
    const banner = data?.banners?.find((item) => item?.position === keyName);

    if (banner) {
      deleteAdsApi({ data: { id: banner?._id } })
        .then(() => {
          refetch?.();
          toast({
            title: "Delete Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setFileSelected((prev) => ({
            ...prev,
            [fieldName]: null,
          }));
        })
        .catch((error) => {
          toast({
            title:
              error?.response?.data?.errors?.[0]?.msg ||
              error?.response?.data?.msg ||
              "Delete Fail",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });

      return;
    }

    setFileSelected((prev) => ({
      ...prev,
      [fieldName]: null,
    }));
  };

  const validate = () => {
    const errorArray = Object.values(error);

    return !isEmpty(errorArray?.filter((item) => !!item));
  };

  const onSubmitMarquee = () => {
    if(!textMarquee) return;
    saveMarqueeApi({
      data: {marqueeText: textMarquee},
    })
      .then(() => {
        refetchMarquee?.();
        toast({
          title: "Update marquee Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Update marquee Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  }

  const onSubmit = (values) => {
    const isValid = validate();

    if (isValid) return;

    const formData = new FormData();

    Object.keys(fileSelected).forEach((key) => {
      if (fileSelected?.[key]?.file) {
        formData.append(key, fileSelected?.[key]?.file);
      }
    });

    formData.append("linkLeft", values?.linkLeft);
    formData.append("linkRight", values?.linkRight);
    formData.append("linkLeft1", values?.linkLeft1);
    formData.append("linkRight1", values?.linkRight1);

    uploadAdsApi({
      data: formData,
    })
      .then(() => {
        refetch?.();
        toast({
          title: "Upload Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Upload Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Banner Ads
            </Text>
          </CardHeader>
          <CardBody pb={4}>
            <form>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem>
                  <FileSelect
                    label="Banner left"
                    name="bannerLeftFile"
                    filePreview={fileSelected.bannerLeftFile?.filePreview}
                    error={error.bannerLeftFile}
                    handleFileSelect={handleFileSelect}
                    handleErrorFile={handleErrorFile}
                    handleRemoveFile={handleRemoveFile}
                  />
                </GridItem>
                <GridItem>
                  <FileSelect
                    label="Banner right"
                    name="bannerRightFile"
                    filePreview={fileSelected.bannerRightFile?.filePreview}
                    error={error.bannerRightFile}
                    handleFileSelect={handleFileSelect}
                    handleErrorFile={handleErrorFile}
                    handleRemoveFile={handleRemoveFile}
                  />
                </GridItem>
                <GridItem>
                  <FileSelect
                    label="Banner left 1"
                    name="bannerLeftFile1"
                    filePreview={fileSelected.bannerLeftFile1?.filePreview}
                    error={error.bannerLeftFile1}
                    handleFileSelect={handleFileSelect}
                    handleErrorFile={handleErrorFile}
                    handleRemoveFile={handleRemoveFile}
                  />
                </GridItem>
                <GridItem>
                  <FileSelect
                    label="Banner right 1"
                    name="bannerRightFile1"
                    filePreview={fileSelected.bannerRightFile1?.filePreview}
                    error={error.bannerRightFile1}
                    handleFileSelect={handleFileSelect}
                    handleErrorFile={handleErrorFile}
                    handleRemoveFile={handleRemoveFile}
                  />
                </GridItem>
                <GridItem>
                  <InputController
                    control={control}
                    name="linkLeft"
                    label="Link redirect banner left"
                    styleContainer={{ pt: "4" }}
                    isRequired
                  />
                </GridItem>
                <GridItem>
                  <InputController
                    control={control}
                    name="linkRight"
                    label="Link redirect banner right"
                    styleContainer={{ pt: "4" }}
                    isRequired
                  />
                </GridItem>
                <GridItem>
                  <InputController
                    control={control}
                    name="linkLeft1"
                    label="Link redirect banner left 1"
                    styleContainer={{ pt: "4" }}
                    isRequired
                  />
                </GridItem>
                <GridItem>
                  <InputController
                    control={control}
                    name="linkRight1"
                    label="Link redirect banner right 1"
                    styleContainer={{ pt: "4" }}
                    isRequired
                  />
                </GridItem>
              </Grid>
            </form>
            <Flex mt={3} columnGap={3} justifyContent={"end"}>
              <Box>
                <Button
                  colorScheme="blue"
                  isLoading={uploading}
                  disabled={(!isDisableSaveBtn || deleting) && !isDirty}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </Button>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt={5}>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Marquee
            </Text>
          </CardHeader>
          <CardBody pb={4}>
            <form>
              <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                <GridItem>
                  <Input
                    type="area"
                    control={control}
                    name="marquee"
                    label="Text marquee"
                    styleContainer={{ pt: "4" }}
                    isRequired
                    value={textMarquee}
                    onChange={e => setTextMarquee(e.target.value)}
                  />
                </GridItem>
              </Grid>
            </form>
            <Flex mt={3} columnGap={3} justifyContent={"end"}>
              <Box>
                <Button
                  colorScheme="blue"
                  isLoading={loadingMarquee}
                  disabled={!textMarquee}
                  onClick={(onSubmitMarquee)}
                >
                  Save
                </Button>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default BannerAds;
