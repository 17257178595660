import React, { useState, useEffect } from "react";

import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  useColorModeValue,
  Flex,
  List,
  ListItem,
  Divider,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import isEmpty from "lodash/isEmpty";

import { API_ROUTES, ROOT_API } from "utils/constant";
import Loading from "components/Layout/Loading";

const ChooseCommentatorDialog = ({
  matchDetail,
  isOpen,
  onOpen,
  onClose,
  fetchData,
}) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const textColor = useColorModeValue("gray.500", "white");

  const [selectedCommentator, setSelectedCommentator] = useState([]);

  const [{ data: commentatorData, loading: commentatorLoading }] = useAxios(
    {
      url: ROOT_API + API_ROUTES.GET_COMMENTATOR,
    },
    {
      useCache: false,
    }
  );
  const [{ loading: addCommentatorLoading }, addCommentatorApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.SET_HOT_MATCH,
    },
    { manual: true }
  );

  useEffect(() => {
    setSelectedCommentator(matchDetail?.commentators || []);
  }, [matchDetail?.commentators]);

  const addCommentator = async () => {
    addCommentatorApi({
      data: {
        matchId: matchDetail.matchId,
        usernames: selectedCommentator,
        isHot: true,
      },
    })
      .then(() => {
        toast({
          title: "Set hot Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        fetchData();
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Set hot Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onSetNoHot = () => {
    addCommentatorApi({
      data: {
        matchId: matchDetail.matchId,
        usernames: selectedCommentator,
        isHot: false,
      },
    })
      .then(() => {
        toast({
          title: "Set no hot Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        fetchData();
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Set no hot Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleSelectedCommentator = (commentator) => {
    const commentatorExists = selectedCommentator.find(
      (item) => item === commentator.username
    );

    if (commentatorExists) {
      const newCommentators = selectedCommentator.filter(
        (item) => item !== commentator.username
      );

      setSelectedCommentator(newCommentators);
      return;
    }

    setSelectedCommentator((prevState) => [...prevState, commentator.username]);
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        size="xl"
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Choose Commentator</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {commentatorLoading ? (
              <Loading />
            ) : (
              <List spacing={3} display="flex" flexDirection="column">
                <CheckboxGroup value={selectedCommentator} name="kol-radio">
                  {commentatorData?.data?.length
                    ? commentatorData.data.map((commentator) => (
                        <div>
                          <Checkbox
                            key={commentator._id}
                            value={commentator.username}
                            onChange={() => {
                              handleSelectedCommentator(commentator);
                            }}
                          >
                            <Flex
                              direction={"row"}
                              columnGap={4}
                              m={2}
                              w="full"
                              onClick={() => {
                                handleSelectedCommentator(commentator);
                              }}
                            >
                              <FormLabel
                                htmlFor={`r-${commentator._id}`}
                                w={"full"}
                                m={0}
                              >
                                <Flex
                                  direction="row"
                                  alignItems="center"
                                  columnGap={4}
                                  m={2}
                                  cursor={"pointer"}
                                >
                                  <Avatar src={ROOT_API + commentator.avatar} />
                                  <ListItem
                                    color={textColor}
                                    wordBreak="break-all"
                                  >
                                    {commentator?.name}
                                  </ListItem>
                                </Flex>
                              </FormLabel>
                            </Flex>
                            <Divider />
                          </Checkbox>
                        </div>
                      ))
                    : ""}
                </CheckboxGroup>
              </List>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
                setSelectedCommentator(null);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                addCommentator();
              }}
              disabled={
                isEmpty(selectedCommentator) ||
                commentatorLoading ||
                addCommentatorLoading
              }
            >
              Set Hot
            </Button>
            {matchDetail?.isHot && (
              <Button
                colorScheme="red"
                ml={3}
                disabled={addCommentatorLoading}
                onClick={onSetNoHot}
              >
                Set No Hot
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ChooseCommentatorDialog;
