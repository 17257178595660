// Chakra imports
import {
  Flex,
  Table,
  Button,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Input,
  InputRightElement,
  InputGroup,
  useToast,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useRef, useState } from "react";
import useAxios from "axios-hooks";
import { TablePagination } from "@trendmicro/react-paginations";
import { initialFilter } from "utils/constant";
import { SearchIcon } from "@chakra-ui/icons";
import Loading from "components/Layout/Loading";
import { axiosPost } from "utils/api";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import TeamTableRow from "components/Team/TeamTableRow";
import CreateTeamDialog from "components/Team/CreateTeamDialog";
import Row from "./components/Row";
import DatePicker from "components/DatePicker/DatePicker";

export default function TrackingHistory() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [filter, setFilter] = useState(initialFilter);
  const toast = useToast();
  const [searchValue, setSearchValue] = useState("");
  const refSearchButton = useRef(null);
  const [userSelected, setUserSelected] = useState("");
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: ROOT_API + API_ROUTES.TRACKING_HISTORY,
      params: filter,
    },
    {
      useCache: false,
    }
  );
  const [
    { data: userData, loading: loadingCode, error: errorCode },
    refetchCode,
  ] = useAxios(
    {
      url: ROOT_API + API_ROUTES.GET_USER_LIST,
    },
    {
      useCache: false,
    }
  );

  const onChangeDate = (type) => (date) => {
    setDateFilter((prev) => ({
      ...prev,
      ...(type === "startDate" && { endDate: null }),
      [type]: date,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Tracking history
          </Text>
        </CardHeader>
        <CardBody pb={4}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Flex flexWrap="wrap" alignItems={"end"} gap={3} mb={3}>
                <FormControl
                  display="flex"
                  flexDirection={"column"}
                  width={{ base: "full", sm: "300px" }}
                  maxW="300px"
                >
                  <FormLabel
                    m="0"
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                  >
                    Start Date
                  </FormLabel>
                  <DatePicker
                    selectedDate={dateFilter.startDate}
                    minDate={
                      new Date(new Date().setMonth(new Date().getMonth() - 1))
                    }
                    maxDate={new Date()}
                    onChange={(date) => onChangeDate("startDate")(date)}
                  />
                </FormControl>
                <FormControl
                  display="flex"
                  flexDirection={"column"}
                  width={{ base: "full", sm: "300px" }}
                  maxW="300px"
                >
                  <FormLabel
                    m="0"
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                  >
                    End Date
                  </FormLabel>
                  <DatePicker
                    selectedDate={dateFilter.endDate}
                    minDate={
                      new Date(new Date().setMonth(new Date().getMonth() - 1))
                    }
                    maxDate={new Date()}
                    onChange={(date) => onChangeDate("endDate")(date)}
                  />
                </FormControl>
                <FormControl
                  display="flex"
                  flexDirection={"column"}
                  width={{ base: "full", sm: "300px" }}
                  maxW="300px"
                >
                  <FormLabel
                    m="0"
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                  >
                    Match Name
                  </FormLabel>
                  <InputGroup>
                    <InputRightElement pointerEvents="none">
                      <SearchIcon color="gray.300" />
                    </InputRightElement>
                    <Input
                      type="text"
                      variant="outline"
                      placeholder="Search by match name"
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                      onKeyUp={(event) => {
                        if (event.key === "Enter" && event?.target?.value) {
                          const searchBtn = refSearchButton?.current;
                          if (searchBtn !== null) {
                            searchBtn.click();
                          }
                        }
                      }}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl
                  display="flex"
                  flexDirection={"column"}
                  width={{ base: "full", sm: "300px" }}
                  maxW="300px"
                >
                  <FormLabel
                    m="0"
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                  >
                    User
                  </FormLabel>
                  <Select
                    placeholder="Select user"
                    onChange={(e) => {
                      setUserSelected(e.target.value);
                    }}
                    value={userSelected}
                  >
                    {userData?.data?.length
                      ? userData.data.map((user) => (
                          <option
                            key={user._id}
                            value={user._id}
                            fontWeight={500}
                          >
                            {user.username}
                          </option>
                        ))
                      : "No user"}
                  </Select>
                </FormControl>
                <Button
                  ref={refSearchButton}
                  disabled={!userSelected && !searchValue}
                  colorScheme="blue"
                  onClick={() => {
                    setFilter({
                      ...filter,
                      userId: userSelected,
                      searchKeyword: searchValue,
                      pageIndex: 0,
                    });
                  }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    setSearchValue("");
                    setUserSelected("");
                    setFilter({
                      ...filter,
                      userId: "",
                      searchKeyword: "",
                      pageIndex: 0,
                    });
                  }}
                >
                  Reset
                </Button>
              </Flex>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th borderColor={borderColor} color="gray.400">
                      Type
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Content
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Match
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      By user
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Updated At
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.length
                    ? data?.data?.map((row, index, arr) => {
                        return (
                          <Row
                            row={row}
                            isLast={index === arr.length - 1 ? true : false}
                            key={index}
                            fetchData={refetch}
                          />
                        );
                      })
                    : ""}
                </Tbody>
              </Table>
              <Flex justifyContent={"flex-end"}>
                <TablePagination
                  type="full"
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={({ page, pageLength }) => {
                    console.log(page);
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                  prevPageRenderer={() => <i className="fa fa-angle-left" />}
                  nextPageRenderer={() => <i className="fa fa-angle-right" />}
                />
              </Flex>
            </>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}
