import React, { useEffect, useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  GridItem,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidateMessage } from "utils/constant";
import InputController from "components/Form/InputController";
import { API_ROUTES } from "utils/constant";
import { ROOT_API } from "utils/constant";
import useAxios from "axios-hooks";

const FormEditCommentator = ({ matchDetail, isOpen, onClose, fetchData }) => {
  const cancelRef = useRef();
  const toast = useToast();
  const [{ loading }, updateTimeApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.UPDATE_TIME,
    },
    { manual: true }
  );

  useEffect(() => {
    reset({
      rtmp: matchDetail?.rtmp,
      cdn_link: matchDetail?.cdn_link,
    });
  }, [matchDetail]);

  const schemaUpdate = yup.object().shape({
    rtmp: yup.string().nullable().required(ValidateMessage.required),
    cdn_link: yup.string().nullable().required(ValidateMessage.required),
  });
  const schemaCreate = yup.object().shape({
    name: yup.string().nullable().required(ValidateMessage.required),
    rtmp: yup.string().nullable().required(ValidateMessage.required),
    cdn_link: yup.string().nullable().required(ValidateMessage.required),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(!matchDetail ? schemaCreate : schemaUpdate),
  });

  const onSubmit = (dataForm) => {
    console.log("🚀 ~ onSubmit ~ dataForm:", dataForm)
    // updateTimeApi({
    //   data: {
    //     ...dataForm,
    //     matchId: matchDetail?.matchId,
    //     time: moment(dataForm?.time).valueOf() / 1000,
    //   },
    // })
    //   .then(() => {
    //     fetchData();
    //     toast({
    //       title: "Update Time Successfully",
    //       status: "success",
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //     onClose?.();
    //   })
    //   .catch((error) => {
    //     toast({
    //       title:
    //         error?.response?.data?.errors?.[0]?.msg ||
    //         error?.response?.data?.msg ||
    //         "Update Time Fail",
    //       status: "error",
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //   });
  };

  return (
    <AlertDialog
      size="lg"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          <div>Update Information commentator</div>
          {matchDetail && (
            <Flex mt={2} flex>
              <Text whiteSpace="nowrap" fontWeight={400} pr={2}>
                Commentator name:
              </Text>
              <Text>
                {`${matchDetail?.commentators?.map((item) => item).join(", ")}`}
              </Text>
            </Flex>
          )}
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form className="match-form">
            <div>
              {!matchDetail && (
                <GridItem w="100%">
                  <InputController
                    type="text"
                    control={control}
                    name="name"
                    label="Name"
                    isRequired
                  />
                </GridItem>
              )}
              <GridItem w="100%">
                <InputController
                  type="text"
                  control={control}
                  name="rtmp"
                  label="RTMP"
                  isRequired
                />
              </GridItem>
              <GridItem w="100%" mt={3}>
                <InputController
                  type="text"
                  control={control}
                  name="cdn_link"
                  label="CDN Link"
                  isRequired
                />
              </GridItem>
            </div>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            OK
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default FormEditCommentator;
