import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Button,
  Text,
  useColorModeValue,
  Box,
  Grid,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import { isEmpty } from "lodash";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { ROOM_CHAT, ROOT_API, Roles, ValidateMessage } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputController from "components/Form/InputController";
import SelectController from "components/Form/SelectController";
import { useForm } from "react-hook-form";
import EditorController from "components/Form/EditorController";
import { isJsonString } from "utils/helpers";
import CheckboxController from "components/Form/CheckboxController";

const PinMessage = () => {
  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "white");
  const [matches, setMatches] = useState([]);
  const [customerCare, setCustomerCare] = useState([]);
  const [commentators, setCommentators] = useState([]);
  const userInfoLocalStorage = localStorage.getItem("isUserShow");
  const user = isJsonString(userInfoLocalStorage);

  const [{ data }] = useAxios(
    {
      url: `${ROOT_API}${API_ROUTES.MATCH_LIVING}`,
    },
    {
      useCache: false,
    }
  );
  const [{ data: users }] = useAxios({
    url: `${ROOT_API}${API_ROUTES.CUSTOMER_CARE}`,
  });
  const [{ loading: createLoading }, createPinMessage] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.PIN_MESSAGE, 
    },
    { manual: true }
  );
  useEffect(() => {
    setMatches(
      data?.data?.map((item) => ({
        value: item?.matchId,
        label: `${item?.home_name} vs ${item?.away_name} - ${item.matchId}`,
      }))
    );
  }, [data?.data]);

  useEffect(() => {
    setCustomerCare(
      users?.data?.map((item) => ({
        value: item?.username,
        label: `${item.username} - ${item.name}`,
      }))
    );
  }, [users?.data]);

  const schema = yup.object().shape({
    msg: yup.string().nullable().required(ValidateMessage.required),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      msg: "",
      matchId: undefined,
      isPin: false,
    },
  });

  const onSubmit = (data) => {
    let payload = {
      ...data,
      matchId: data?.matchId?.value,
      commentatorId: data?.commentatorId?.value,
      username: data?.username?.value,
    };
    if (!data?.matchId?.value) {
      payload = {
        ...payload,
        room: ROOM_CHAT.HOME
      };
    }

      createPinMessage({
        data: payload,
      })
        .then(() => {
          handleSuccess();
        })
        .catch((error) => {
          handleError(error);
        });
  };
  const handleSuccess = () => {
    toast({
      title: `Tạo tin nhắn thành công`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleError = (error) => {
    toast({
      title: 'Tạo tin nhắn thất bại',
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };
  const onSelectMatchChange = (item) => {
    const match = data?.data?.find((i) => i.matchId === item.value);
    setCommentators(
      match?.commentatorIds?.map((cmt) => ({
        value: cmt?._id,
        label: cmt?.username,
      }))
    );
  };
  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Create Pin Message
            </Text>
          </CardHeader>
          <CardBody pb={4}>
            <form>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem>
                  <SelectController
                    // styleContainer={{ pt: "4" }}
                    styleBoxInput={{
                      flexShrink: "1",
                      flexGrow: "1",
                      flexBasis: "0",
                    }}
                    control={control}
                    options={matches}
                    name="matchId"
                    label="Trận đấu"
                    isRequired
                    onChange={onSelectMatchChange}
                  />
                </GridItem>
                <GridItem>
                  <SelectController
                    styleBoxInput={{
                      flexShrink: "1",
                      flexGrow: "1",
                      flexBasis: "0",
                    }}
                    control={control}
                    options={commentators}
                    name="commentatorId"
                    label="Bình luận viên"
                    isRequired
                  />
                </GridItem>
                {user?.role !== Roles.CUSTOMER_CARE && (
                  <GridItem>
                    <SelectController
                      styleBoxInput={{
                        flexShrink: "1",
                        flexGrow: "1",
                        flexBasis: "0",
                      }}
                      control={control}
                      options={customerCare}
                      name="username"
                      label="Chuyển hóa"
                      isRequired
                    />
                  </GridItem>
                )}
                <GridItem>
                  <CheckboxController
                    styleContainer={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    minWLabel="40px"
                    control={control}
                    name="isPin"
                    label="Pin tin nhắn"
                  />
                </GridItem>
              </Grid>
              <Grid mt={4}>
                <GridItem>
                  <EditorController
                    control={control}
                    name="msg"
                    label="Nội dung"
                    isRequired
                  />
                </GridItem>
              </Grid>
            </form>
            <Flex mt={3} columnGap={3} justifyContent={"end"}>
              <Box>
                <Button
                  colorScheme="blue"
                  isLoading={createLoading}
                  // disabled={!isDisableSaveBtn || deleting}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </Button>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default PinMessage;
